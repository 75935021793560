@tailwind base;
@tailwind components;
@tailwind utilities;

/* Coolvetica Rg */
@font-face {
  font-family: 'Coolvetica Rg';
  src: url('./fonts/coolvetica\ rg.ttf') format('ttf');
  font-weight: 400;
  font-style: normal;
}

body {
  margin: 0;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: auto;
  background-color: transparent
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.doctor-status {
  font-size: 15px !important;
  font-family: Poppins !important;
  font-weight: 500 !important;
  color: #777 !important;
  padding-left: 8px !important;
  margin-left: 0 !important;
}

.doctor-status-outline {
  border: 1px solid #ddd !important;
  width: 140px !important;
  padding-left: 2px !important;
  border-radius: 25px !important;
  /* margin-left: 25px !important; */
  height: 38px !important;
}

.detailView {
  .status-tog {
    top: 0px !important;
    margin-left: 5px !important;
    height: 37px !important;
    display: inline-block !important;
  }

  .react-toggle-track {
    cursor: pointer !important;
    position: relative !important;
    width: 90px !important;
    height: 35px !important;
    padding: 0 !important;
    border-radius: 30px !important;
    background-color: #ddd !important;
    margin-top: 6px !important;
    transition: all 0.2s ease !important;
  }

  .react-toggle-thumb {
    transition: all 0.5s cubic-bezier(0.23, 1, 0.32, 1) 0ms !important;
    position: relative !important;
    top: -35px !important;
    left: 1px !important;
    width: 35px !important;
    height: 35px !important;
    border: 1px solid #2dea95 !important;
    border-radius: 50% !important;
    background-color: #dadada !important;
    box-sizing: border-box !important;
    transition: all 0.25s ease !important;
  }

  .react-toggle--checked .react-toggle-thumb {
    left: 55px !important;
    background-color: #00bcd4 !important;
  }

  .react-toggle--checked .react-toggle-track {
    background-color: #79d7e3 !important;
  }

  .react-toggle-track-x {
    position: absolute !important;
    width: 10px !important;
    height: 10px !important;
    top: 11px !important;
    bottom: 0 !important;
    margin-top: auto !important;
    margin-bottom: auto !important;
    line-height: 0 !important;
    right: 32px !important;
    opacity: 1;
    font-size: 11px !important;
    transition: opacity 0.25s ease !important;
  }

  .react-toggle-track-check {
    position: absolute !important;
    width: 14px !important;
    height: 10px !important;
    top: 8px !important;
    font-size: 11px !important;
    bottom: 0 !important;
    margin-top: auto !important;
    margin-bottom: auto !important;
    line-height: 0 !important;
    left: 6px !important;
    opacity: 0;
    transition: opacity 0.25s ease;
  }
}

.doctorSidebar {
  .status-tog {
    top: 0px !important;
    margin-left: 5px !important;
    height: 37px !important;
    display: inline-block !important;
  }

  .react-toggle-track {
    cursor: pointer !important;
    position: relative !important;
    width: 67px !important;
    height: 24px !important;
    padding: 0 !important;
    border-radius: 30px !important;
    background-color: #ddd !important;
    margin-top: 6px !important;
    transition: all 0.2s ease !important;
  }

  .react-toggle-thumb {
    transition: all 0.5s cubic-bezier(0.23, 1, 0.32, 1) 0ms !important;
    position: relative !important;
    top: -23px !important;
    left: 1px !important;
    width: 22px !important;
    height: 22px !important;
    border: 1px solid #4d4d4d !important;
    border-radius: 50% !important;
    background-color: red !important;
    box-sizing: border-box !important;
    transition: all 0.25s ease !important;
  }

  .react-toggle--checked .react-toggle-thumb {
    left: 44px !important;
    background-color: #5a7e5d !important;
  }

  .react-toggle--checked .react-toggle-track {
    background-color: #ddd !important;
  }

  .react-toggle-track-x {
    position: absolute !important;
    width: 10px !important;
    height: 10px !important;
    top: 11px !important;
    bottom: 0 !important;
    margin-top: auto !important;
    margin-bottom: auto !important;
    line-height: 0 !important;
    right: 32px !important;
    opacity: 1;
    font-size: 11px !important;
    transition: opacity 0.25s ease !important;
  }

  .react-toggle-track-check {
    position: absolute !important;
    width: 14px !important;
    height: 10px !important;
    top: 8px !important;
    font-size: 11px !important;
    bottom: 0 !important;
    margin-top: auto !important;
    margin-bottom: auto !important;
    line-height: 0 !important;
    left: 6px !important;
    opacity: 0;
    transition: opacity 0.25s ease;
  }
}

.self-text {
  padding-top: 30px !important;
  width: 50% !important;
  padding-right: 1px !important;
  color: #33cfa2 !important;
}

.self-line {
  padding-top: 20px !important;
  padding-left: 0 !important;
  padding-right: 0 !important;
  width: 45% !important;
}

.react-toggle:hover:not(.react-toggle--disabled) .react-toggle-track {
  background-color: #ddd !important;
}

.react-toggle--checked:hover:not(.react-toggle--disabled) .react-toggle-track {
  background-color: #ddd !important;
}

.react-toggle--focus .react-toggle-thumb {
  box-shadow: none !important;
}

.doctor-status-outline .react-toggle .react-toggle-screenreader-only {
  display: none !important;
}

.sideNav-card {
  box-shadow: 0 3px 25px 0 rgba(96, 96, 96, 0.34);
}

.sidebar-box {
  box-shadow: 5px 0 30px 0 rgba(0, 0, 0, .25);
  /* padding: 16px; */
  overflow: auto;
  /* background-color: transparent */
  background-color: #fff;
}

.login .react-tel-input {
  .form-control {
    border: 1px solid #A9A9A9 !important;
    width: 100% !important;
    opacity: 0.7 !important;
    height: 40px !important;
    border-radius: 5px !important;
  }

  .flag-dropdown {
    background-color: white !important;
    border-right: none !important;
    border-radius: 5px !important;
  }
}

input[type="text"] {
  border-bottom: 1px solid #979797;

  &:focus-visible {
    outline: none;
  }
}

input[type="number"] {
  border-bottom: 1px solid #979797;

  &:focus-visible {
    outline: none;
  }
}


.addDrug input[type="text"] {
  border-bottom: 1px solid #D9D9D9;

  &:focus-visible {
    outline: none;
  }
}

.addDrug input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 50px white inset;
  /* Change the color to your own background color */
  -webkit-text-fill-color: #333;
}

.addDrug input:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0 50px white inset;
  /*your box-shadow*/
  -webkit-text-fill-color: #333;
}


.react-datepicker__calendar-icon {
  right: 0;
  cursor: pointer;
}

.react-datepicker__view-calendar-icon input {
  padding: 6px 10px 5px 0px !important;
}

.react-datepicker-popper {
  /* left: 35px !important; */
  top: -80px !important;
}

.commonTable tbody {
  font-size: 13px;
  border-radius: 20px;
  box-shadow: -3px -3px 15px 1px rgba(96, 96, 96, .15);
}

.commonTable tbody tr {
  text-align: center;
  /* cursor: pointer; */
}

.commonTable tbody tr:not(:last-child) {
  border-bottom: 1px solid #e1e1e1;
}

.commonTable tbody tr:hover {
  border-left: 5px solid #383678;
  box-shadow: 0 2px 10px 0 rgba(64, 63, 63, .2);
  -moz-box-shadow: 0 2px 10px 0 rgba(64, 63, 63, .2);
  -webkit-box-shadow: 0 2px 10px 0 rgba(64, 63, 63, .2);
  background-color: #f7f7f7;
}

.commonTable tbody td {
  padding: 20px;
  color: #777;
  white-space: nowrap;
  overflow: hidden;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
}

.commonTable thead th {
  padding: 5px 20px;
  font-size: 14px;
  color: #555;
  font-weight: 500;
}

.css-13cymwt-control {
  border-width: 0 !important;
  border-style: none !important;
  border-bottom: 2px !important;

  .css-1u9des2-indicatorSeparator {
    display: none !important;
  }
}

.css-1a9d28t-control {
  border-width: 0 !important;
  border-style: none !important;
  border-bottom: 2px !important;

  .css-1u9des2-indicatorSeparator {
    display: none !important;
  }
}

.reg-user-icon {
  width: 100px;
  height: 100px;
  border: 2px solid gray;
  border-radius: 22px;
  padding: 0;
  cursor: pointer;
}

.form-control {
  width: 100% !important;
  background: #f4f4f4 !important;
  border-top: none !important;
  border-left: none !important;
  border-right: none !important;
  border-bottom: 1px solid #A9A9A9 !important;
  border-radius: 0% !important;
}

.selected-flag {
  background: #f4f4f4 !important;
  border-top: none !important;
  border-left: none !important;
  border-right: none !important;
  /* border-bottom: 1px solid #A9A9A9 !important; */
  border-radius: 0% !important;
}

.dropdown-toggle {
  width: 100%;
  height: 50px;
  color: rgb(88, 88, 88);
  background: #f4f4f4 !important;
  border-top: none !important;
  border-left: none !important;
  border-right: none !important;
  border-bottom: 1px solid #A9A9A9 !important;
  border-radius: 0% !important;
}

.registerDate .react-datepicker__view-calendar-icon input {
  height: 100%;
  width: 100%;
  background: #f4f4f4;
  border-bottom: 1px solid #A9A9A9;
}

.react-datepicker__input-container {
  height: 100%;
  display: flex !important;
  align-items: center !important;
}

.react-datepicker-wrapper {
  height: 50px;
  width: 200px;
}

.search input {
  border: none;
  border-bottom: 1px solid grey;
}

.underline-heading {
  font-weight: 600;
  text-decoration: none;
  border-bottom: 3px solid #383678;
  /* font-family: "Coolvetica Rg"; */
  color: #4f4f4f;
  font-size: 24px;
}

.scheduleDate .react-datepicker-wrapper {
  width: 100%;
}

.scheduleDate .react-datepicker__input-container {
  border: 1px solid grey;
  border-radius: 10px;
  padding: 0px 10px;
}

.scheduleDate .react-datepicker-wrapper .react-datepicker__input-container input:focus {
  border: none;
  outline: none;
  background: white;
}

/* .scheduleFilter {
  border: 1px solid grey;
  border-radius: 10px;
} */

.scheduleFilter .customSelectCSS {
  width: 100%;
  background: white;
  border: 1px solid grey;
  border-radius: 10px;

  .css-b62m3t-container {
    border: 1px soid #808080;
    background: white;
    border-radius: 10px;
  }

  .css-pa5n7w-control {
    background: white;
    border: 1px soid #808080;
  }
}

.medical-history-container {
  border-radius: 8px;
  background-color: #fafafa;
  -webkit-box-shadow: 1px 1px 10px 0 rgba(0, 0, 0, .2);
  box-shadow: 1px 1px 10px 0 rgba(0, 0, 0, .2);
  padding-top: 15px;
  padding-bottom: 15px;
}

.advSearch .react-datepicker__view-calendar-icon input {
  height: 100%;
  width: 100%;
  border-bottom: 1px solid #A9A9A9;
}

.advSearch .react-datepicker-wrapper {
  width: 100%;
}

.search-icon {
  transition: opacity 0.3s ease-in-out;
}

.search-visible {
  opacity: 1;
}

.search-hidden {
  opacity: 0;
}

.search input:focus {
  outline: none;
}

.lineAroundText::before {
  border-top: 1px solid #dfdfdf;
  content: "";
  margin: 0 auto;
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
}


.registerPatient select:focus {
  outline: none;
  border-color: initial;

  .lineAroundText::before {
    border-top: 1px solid #dfdfdf;
    content: "";
    margin: 0 auto;
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
  }
}

.prescriptionPreviewTable thead th {
  padding: 10px;
  text-align: center;
}

.prescriptionPreviewTable tbody td {
  font-size: 12px;
  text-align: center;
}

.prescriptionPreviewTable td {
  padding: 10px;
}

.prescriptionPreviewTable table,
.prescriptionPreviewTable th,
.prescriptionPreviewTable td {
  border: 1px solid black;
  border-collapse: collapse;
}

.prescriptionTable tbody td {
  padding: 10px;
}

.prescriptionTable tr:not(:last-child) {
  border-bottom: 1px solid #e1e1e1;
}

.bottom-border {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  border-bottom: 1px solid black;
  /* Add bottom border */
}

.createUser .css-195904m-IndicatorsContainer {
  background: #f4f4f4;
}

.createUser .css-1vaffrc-ValueContainer {
  background: #f4f4f4;
}

.createUser select:focus {
  outline: none;
  border-color: initial;
}

.scheduleDate input {
  border: none !important;
}

.scheduleDate .react-datepicker-wrapper .react-datepicker__input-container input:focus {
  border: none;
  outline: none;
  background: white;
}

.scheduleFilter .customSelectCSS {
  width: 100%;
  background: white;

  .css-b62m3t-container {
    border: 1px soid #808080;
    background: white;
    border-radius: 10px;
  }

  .css-pa5n7w-control {
    background: white;
    border: 1px soid #808080;
  }
}

.admin-create {
  .react-tel-input {
    border-bottom: 1px solid #979797 !important;

    .form-control {
      border: none !important
    }

    .flag-dropdown {
      background-color: none !important;
      border: none !important;
      border-radius: none !important;
      border-bottom: none !important;
    }
  }
}

.dayz {
  width: 100%;
  height: 71%;
  box-sizing: border-box;
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
  align-items: stretch;
}

.dayz .x-labels {
  height: 30px;
  align-items: center;
  display: flex;
  flex-direction: row;
}

.dayz .x-labels .day-label {
  display: flex;
  flex-grow: 1;
  flex-basis: 1px;
  padding-left: 8px;
}

.dayz .body {
  flex-grow: 1;
  display: flex;
  flex-direction: row;
  position: relative;
  height: 450px
}

.dayz .body .y-labels {
  position: absolute;
  width: 100%;
  height: 100%;
  width: 60px;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.dayz .body .y-labels .all-day,
.dayz .body .y-labels .hour {
  width: 100%;
  flex: 1;
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-indent: 8px;
  text-align: left;
  border-bottom: 1px solid #b6b6b6;
}

.dayz .body .y-labels .all-day {
  flex-grow: 0;
}

.dayz .body .days {
  flex: 1;
  display: flex;
  flex-direction: row;
  position: relative;
  box-sizing: border-box;
  border-top: 1px solid #b6b6b6;
  border-left: 1px solid #b6b6b6;
}

.dayz .day {
  border-right: 1px solid #b6b6b6;
  border-bottom: 1px solid #b6b6b6;
  box-sizing: border-box;
}

.dayz .day .event>* {
  height: 100%;
}

.dayz .day.highlight {
  background-color: #ececec;
}

.dayz.month .event.stack-1,
.dayz.week .all-day .event.stack-1,
.dayz.day .all-day .event.stack-1 {
  margin-top: 20px;
}

.dayz.month .event.stack-2,
.dayz.week .all-day .event.stack-2,
.dayz.day .all-day .event.stack-2 {
  margin-top: 40px;
}

.dayz.month .event.stack-3,
.dayz.week .all-day .event.stack-3,
.dayz.day .all-day .event.stack-3 {
  margin-top: 60px;
}

.dayz.month .event.stack-4,
.dayz.week .all-day .event.stack-4,
.dayz.day .all-day .event.stack-4 {
  margin-top: 80px;
}

.dayz.month .event.stack-5,
.dayz.week .all-day .event.stack-5,
.dayz.day .all-day .event.stack-5 {
  margin-top: 100px;
}

.dayz.month .event.stack-6,
.dayz.week .all-day .event.stack-6,
.dayz.day .all-day .event.stack-6 {
  margin-top: 120px;
}

.dayz.month .event.stack-7,
.dayz.week .all-day .event.stack-7,
.dayz.day .all-day .event.stack-7 {
  margin-top: 140px;
}

.dayz.month .event.stack-8,
.dayz.week .all-day .event.stack-8,
.dayz.day .all-day .event.stack-8 {
  margin-top: 160px;
}

.dayz.month .event.stack-9,
.dayz.week .all-day .event.stack-9,
.dayz.day .all-day .event.stack-9 {
  margin-top: 180px;
}

.dayz.week .x-labels,
.dayz.day .x-labels {
  padding-left: 60px;
}

.dayz.week .days,
.dayz.day .days {
  margin-left: 60px;
}

.dayz.week .day,
.dayz.day .day {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.dayz.week .day .all-day,
.dayz.day .day .all-day {
  flex: 1;
  flex-grow: 0;
}

.dayz.week .day .all-day .event,
.dayz.day .day .all-day .event {
  height: 20px;
}

.dayz.week .day .events,
.dayz.day .day .events {
  flex: 1;
  position: relative;
  cursor: pointer;
}

.dayz.week .day .events .event.span-1,
.dayz.day .day .events .event.span-1 {
  width: 100%;
  position: absolute;
}

.dayz.week .day .label,
.dayz.day .day .label {
  display: none;
}

.dayz.week .events .day .event.span-1 {
  width: 14.28571%;
}

.dayz.month .days {
  display: grid;
  grid-auto-rows: 1fr;
  grid-template-columns: repeat(7, minmax(4rem, 1fr));
}

.dayz.month .days .day .label {
  text-align: right;
  padding: 5px;
}

.dayz.month .days .day .event {
  height: 29px;
}

.dayz.month .days .day .event.span-1 .evbody>div {
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
  overflow: hidden;
}

.dayz.month .days .day.outside {
  background-color: #F2F2F2;
}

.dayz .event {
  background: #383678;
  color: white;
  box-sizing: border-box;
  position: relative;
  border: 1px solid white;
  border-radius: 4px;
}

.dayz .event.color-1 .evbody {
  background: #4D4D4D;
  color: white;
}

.dayz .event.color-2 .evbody {
  background: #5DA5DA;
  color: black;
}

.dayz .event.color-3 .evbody {
  background: #FAA43A;
  color: white;
}

.dayz .event.color-4 .evbody {
  background: #60BD68;
  color: black;
}

.dayz .event.color-5 .evbody {
  background: #F17CB0;
  color: black;
}

.dayz .event.color-6 .evbody {
  background: #B2912F;
  color: white;
}

.dayz .event.color-7 .evbody {
  background: #B276B2;
  color: white;
}

.dayz .event.color-8 .evbody {
  background: #DECF3F;
  color: black;
}

.dayz .event.color-9 .evbody {
  background: #F15854;
  color: white;
}

.dayz .event.color-10 .evbody {
  background: #F5DEB3;
  color: black;
}

.dayz .event.span-1 {
  z-index: 1;
  width: calc(100% + 1px - 1px);
}

.dayz .event.span-1.is-continued {
  width: calc(100% + -11px);
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.dayz .event.span-1.is-continuation {
  width: calc(100% + -11px);
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.dayz .event.span-1.is-continued.is-continuation {
  width: calc(100% + -21px);
}

.dayz .event.span-2 {
  z-index: 1;
  width: calc(200% + 2px - 1px);
}

.dayz .event.span-2.is-continued {
  width: calc(200% + -10px);
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.dayz .event.span-2.is-continuation {
  width: calc(200% + -10px);
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.dayz .event.span-2.is-continued.is-continuation {
  width: calc(200% + -20px);
}

.dayz .event.span-3 {
  z-index: 1;
  width: calc(300% + 3px - 1px);
}

.dayz .event.span-3.is-continued {
  width: calc(300% + -9px);
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.dayz .event.span-3.is-continuation {
  width: calc(300% + -9px);
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.dayz .event.span-3.is-continued.is-continuation {
  width: calc(300% + -19px);
}

.dayz .event.span-4 {
  z-index: 1;
  width: calc(400% + 4px - 1px);
}

.dayz .event.span-4.is-continued {
  width: calc(400% + -8px);
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.dayz .event.span-4.is-continuation {
  width: calc(400% + -8px);
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.dayz .event.span-4.is-continued.is-continuation {
  width: calc(400% + -18px);
}

.dayz .event.span-5 {
  z-index: 1;
  width: calc(500% + 5px - 1px);
}

.dayz .event.span-5.is-continued {
  width: calc(500% + -7px);
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.dayz .event.span-5.is-continuation {
  width: calc(500% + -7px);
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.dayz .event.span-5.is-continued.is-continuation {
  width: calc(500% + -17px);
}

.dayz .event.span-6 {
  z-index: 1;
  width: calc(600% + 6px - 1px);
}

.days .evbody {
  font-size: 14px;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
}

.dayz .event.span-6.is-continued {
  width: calc(600% + -6px);
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.dayz .event.span-6.is-continuation {
  width: calc(600% + -6px);
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.dayz .event.span-6.is-continued.is-continuation {
  width: calc(600% + -16px);
}

.dayz .event.span-7 {
  z-index: 1;
  width: calc(700% + 7px - 1px);
}

.dayz .event.span-7.is-continued {
  width: calc(700% + -5px);
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.dayz .event.span-7.is-continuation {
  width: calc(700% + -5px);
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.dayz .event.span-7.is-continued.is-continuation {
  width: calc(700% + -15px);
}

.dayz .event.is-resizable::before,
.dayz .event.is-resizable::after {
  content: " ";
  height: 3px;
  border: 1px solid black;
  right: 15%;
  left: 15%;
  display: block;
  position: absolute;
  border-radius: 5px;
  visibility: hidden;
  cursor: ns-resize;
}

.dayz .event.is-resizable.color-1 .evbody:before,
.dayz .event.is-resizable.color-1 .evbody:after {
  background-color: #272727;
}

.dayz .event.is-resizable.color-2 .evbody:before,
.dayz .event.is-resizable.color-2 .evbody:after {
  background-color: #2c80bf;
}

.dayz .event.is-resizable.color-3 .evbody:before,
.dayz .event.is-resizable.color-3 .evbody:after {
  background-color: #e27f06;
}

.dayz .event.is-resizable.color-4 .evbody:before,
.dayz .event.is-resizable.color-4 .evbody:after {
  background-color: #3d9345;
}

.dayz .event.is-resizable.color-5 .evbody:before,
.dayz .event.is-resizable.color-5 .evbody:after {
  background-color: #ea3786;
}

.dayz .event.is-resizable.color-6 .evbody:before,
.dayz .event.is-resizable.color-6 .evbody:after {
  background-color: #75601f;
}

.dayz .event.is-resizable.color-7 .evbody:before,
.dayz .event.is-resizable.color-7 .evbody:after {
  background-color: #8d4f8d;
}

.dayz .event.is-resizable.color-8 .evbody:before,
.dayz .event.is-resizable.color-8 .evbody:after {
  background-color: #b2a41f;
}

.dayz .event.is-resizable.color-9 .evbody:before,
.dayz .event.is-resizable.color-9 .evbody:after {
  background-color: #e61813;
}

.dayz .event.is-resizable.color-10 .evbody:before,
.dayz .event.is-resizable.color-10 .evbody:after {
  background-color: #ecc16f;
}

.dayz .event.is-resizable:before {
  top: -2px;
}

.dayz .event.is-resizable:after {
  bottom: -2px;
}

.dayz .event.is-resizable.is-resizing:before,
.dayz .event.is-resizable.is-resizing:after,
.dayz .event.is-resizable:hover:before,
.dayz .event.is-resizable:hover:after {
  visibility: visible;
}

.dayz .event .evbody {
  padding-left: 0.4rem;
  height: 100%;
  overflow: hidden;
  cursor: pointer !important;
}

.dayz .event.is-continued {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.dayz .event.is-continued::after {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-style: solid;
  position: absolute;
  -moz-transform: scale(0.9999);
  border-width: 10px 0 10px 10px;
  border-color: transparent transparent transparent #dddddd;
  right: -10px;
  top: -1px;
}

.dayz .event.is-continued.color-1::after {
  border-color: transparent transparent transparent #4D4D4D;
}

.dayz .event.is-continued.color-2::after {
  border-color: transparent transparent transparent #5DA5DA;
}

.dayz .event.is-continued.color-3::after {
  border-color: transparent transparent transparent #FAA43A;
}

.dayz .event.is-continued.color-4::after {
  border-color: transparent transparent transparent #60BD68;
}

.dayz .event.is-continued.color-5::after {
  border-color: transparent transparent transparent #F17CB0;
}

.dayz .event.is-continued.color-6::after {
  border-color: transparent transparent transparent #B2912F;
}

.dayz .event.is-continued.color-7::after {
  border-color: transparent transparent transparent #B276B2;
}

.dayz .event.is-continued.color-8::after {
  border-color: transparent transparent transparent #DECF3F;
}

.dayz .event.is-continued.color-9::after {
  border-color: transparent transparent transparent #F15854;
}

.dayz .event.is-continued.color-10::after {
  border-color: transparent transparent transparent #F5DEB3;
}

.dayz .event.is-continuation {
  margin-left: 10px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.dayz .event.is-continuation::before {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-style: solid;
  position: absolute;
  -moz-transform: scale(0.9999);
  border-width: 10px 10px 10px 0;
  border-color: transparent #dddddd transparent transparent;
  left: -10px;
  bottom: -1px;
}

.dayz .event.is-continuation.color-1::before {
  border-color: transparent #4D4D4D transparent transparent;
}

.dayz .event.is-continuation.color-2::before {
  border-color: transparent #5DA5DA transparent transparent;
}

.dayz .event.is-continuation.color-3::before {
  border-color: transparent #FAA43A transparent transparent;
}

.dayz .event.is-continuation.color-4::before {
  border-color: transparent #60BD68 transparent transparent;
}

.dayz .event.is-continuation.color-5::before {
  border-color: transparent #F17CB0 transparent transparent;
}

.dayz .event.is-continuation.color-6::before {
  border-color: transparent #B2912F transparent transparent;
}

.dayz .event.is-continuation.color-7::before {
  border-color: transparent #B276B2 transparent transparent;
}

.dayz .event.is-continuation.color-8::before {
  border-color: transparent #DECF3F transparent transparent;
}

.dayz .event.is-continuation.color-9::before {
  border-color: transparent #F15854 transparent transparent;
}

.dayz .event.is-continuation.color-10::before {
  border-color: transparent #F5DEB3 transparent transparent;
}

.day .label {
  color: black;
  font-size: 10.5px;
  text-align: left !important;
  font-weight: 700;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;

}

.sigCanvas {
  background-color: white;
  border: 1px solid #000;
  height: 200px;
  width: 400px;
}

.count-cont {
  border: 1px solid #000;
  padding: 36px 10px 55px 10px;
  margin-bottom: 21px;
  border-radius: 4px;
}

.graph-cont {
  border: 1px solid #000;
  border-radius: 5px;
  padding: 10px;
}

.graph-tabular-btn {
  /* margin: -29px 0 3px auto !important; */
  /* display: block; */
  background: #383678 !important;
  border: 1px solid #383678;
  color: white;
  font-family: "Poppins";
  padding: 2px 10px;
  border-radius: 25px;
}

.graph-heading {
  display: block;
  width: 80%;
}

.login-span {
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 14px;
  line-height: 3;
}

.login-parent-div {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  box-shadow: 10px 46px 73px 18px #eee;
}

.bg-none {
  background: none !important;
}

.spadmin-sidenav-rolename {
  color: #777;
  font-size: 12px;
  margin-top: 10px;
  font-weight: 500;
}

.spadmin-sidenav-actorcount {
  color: rgba(0, 0, 0, .5);
  font-size: 15px;
  margin-bottom: -3px;
}

.admin-progress-indicator-bar {
  height: 20px;
  border-radius: 4px;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, .1);
  border: 1px solid #ccc;
  width: 95%;
}

.lh-10 {
  line-height: 10px;
}

.flag-text {
  font-weight: 500;
}

.fw-500 {
  font-weight: 500;
}

.show-menu {
  color: #555;
  font-size: 16px;
  font-family: Poppins;
  padding-left: 10px;
  clear: both;
  width: 100%;
  line-height: 1rem;
  margin-top: 2px;
  margin-bottom: -2px;
}

.commonTable {
  table-layout: fixed;
}

.token-container {
  max-width: 100%;
  overflow: hidden;
  word-wrap: break-word;
  /* Allow text to wrap to the next line */
}